import React, {useState, useEffect} from 'react';
import {makeStyles} from '@mui/styles';

import {Link as RouterLink, NavLink, useHistory} from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search';
import {AppBar, Button, InputAdornment, Link, Menu, MenuItem, TextField, Toolbar, Typography} from '@mui/material';
import styles from './styles';
import {Box} from '@mui/system';
import ExploreIcon from '@mui/icons-material/Explore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {AppLogo} from '@shared/components/Logo';
import {HomeLogo} from '@shared/components/HomeLogo';
import {AnnouncemenetLogo} from '@shared/components/AnnouncementLogo';
import {ProfileLogo} from '@shared/components/ProfileLogo';
import {BellIcon} from '@shared/components/BellIcon';
import {RestaurantIcon} from '@shared/components/RestaurantIcon'
import {useAppDispatch, useAppSelector} from '@store/hooks';
import {appUserProfileSelector, appUserSelector, getProfileAction, signOutAction} from '@store/slices/app.slice';
import {appIsMobile, isBusiness, isCustomer, useQuery} from '@shared/helpers/global.helper';
import {InputEvent} from '@shared/models/input.model';
import Wallet from "@shared/components/Wallet";
import {WalletTags} from "@interfaces/wallet";
import {
    commonSelector,
    getNotificationAnnouncements,
    readAnnouncement,
    getAnnouncement
} from '@store/slices/common.slice';
import {getWalletAction, walletSelector} from "@store/slices/wallet.slice";
import {MembershipLogo} from "@shared/components/MembershipLogo";
import {tokenService} from "@services/token.service";
import {environment} from "@env/environment";
import {getMyTokensAction, tokenSelector} from "@store/slices/token.slice";


const useStyles = makeStyles((theme) => (styles(theme)));

export default function PrimarySearchAppBar() {
    const classes = useStyles();
    const [search, setSearch] = useState('')
    const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notificationAnchorEl, setnotificationAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const openNotification = Boolean(notificationAnchorEl);
    const dispatch = useAppDispatch();
    const userProfile = useAppSelector(appUserProfileSelector);
    const tokenSlice = useAppSelector(tokenSelector);
    const userWallet = useAppSelector(walletSelector);

    const commonSlice = useAppSelector(commonSelector);
    const appUser = useAppSelector(appUserSelector);

    let history = useHistory();

    useEffect(() => {
        // Check to see if user is logged in. If so, get their notifications. Also, check if their profile is set and, if not, set it
        if (appUser && Object.keys(appUser).length > 0) {
            // dispatch(getNotificationAnnouncements());

            if ((!userProfile || Object.keys(userProfile).length === 0)) {
                dispatch(getProfileAction())

                if ((!userWallet || Object.keys(userWallet).length === 0)) {
                    if (userProfile.id) {
                        dispatch(getWalletAction({id: userProfile.id}))
                    }
                }
            }

            if ((!tokenSlice || Object.keys(tokenSlice).length === 0) || !tokenSlice?.myTokenLoaded) {
                dispatch(getMyTokensAction())
            }


        }
    }, []);

    const handleNotificationClick = (event: any) => {
        setnotificationAnchorEl(event.currentTarget);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const readNotification = (announcementId: string) => {
        if (announcementId) {
            dispatch(readAnnouncement({announcementId, history}));
            dispatch(getAnnouncement(announcementId))
            handleNotificationClose();
        }

    };
    const onChange = (event: InputEvent) => {
        setSearch(event.target.value);
        //TODO: this works if the user types in their query, but won't work for a copy-paste
        if (event.target.value.length == 1) {
            setCurrentPath(window.location.pathname);
        }

        if (event.target.value.length > 2) {
            history.push(`/search?query=${event.target.value}`);
        } else {
            history.push(currentPath);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNotificationClose = () => {
        setnotificationAnchorEl(null);
    };

    const redirect = (path: string) => {
        history.push(path);
        setAnchorEl(null);
    }

    const onLogout = () => {
        dispatch(signOutAction(history));
    }

    const retrieveMemberId = () => {
        if (tokenSlice && tokenSlice.myTokenLoaded === true) {

            let cardToken = tokenSlice.myTokens?.data?.find(({parent_id}) => parseInt(parent_id) === parseInt(environment.DEFAULT_RESERVEAT_TOKEN_ID));

            if (cardToken !== undefined) {
                return parseInt(cardToken.id);
            }

            return -1;

        }

        return -1;
    }

    const createTokenActivation = async () => {
        if (tokenSlice && tokenSlice.myTokenLoaded === true) {

            let memberId = retrieveMemberId();

            if (memberId !== -1) {
                const response = await tokenService.createActivation(memberId);
                history.push(`/tokens/${response?.data?.data?.id}/scan`)
            }
        }
    }

    const createURL = async () => {
        if (tokenSlice && tokenSlice.myTokenLoaded === true) {

            let memberId = retrieveMemberId();

            if (memberId !== -1) {
                const response = await tokenService.createActivation(memberId);
                return `/tokens/${response?.data?.data?.id}/scan`;
            }

            return '';

        }

        return '';

    }


    return (
        <>
            {/*{isCustomer() && (<Box p={2} sx={{ display: { sm: 'block', md: 'none' } }}  >
        <form autoComplete="off" className="w-100">
          <TextField
            id="searchBox"
            placeholder=""
            size="small"
            className="w-100"
            onChange={(e: InputEvent) => onChange(e)}
            variant="outlined"
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </form>
      </Box>
      )}*/}

            <div className={classes.grow}>
                <AppBar position="static" className={classes.appbar}>
                    <Toolbar className={classes.toolbar} style={{paddingLeft: '0px', paddingRight: '0px'}}>
                        <Box sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>
                            <Typography>
                                <Link color="inherit" component={RouterLink} to={'/membership'}
                                      style={{textDecoration: 'inherit'}} variant="h5"><AppLogo/></Link>
                            </Typography>
                        </Box>

                        {/*{isCustomer() && (
              <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} className="w-25" >
                <form autoComplete="off" className="w-100">
                  <TextField
                    id="searchBox"
                    placeholder="Search by Memberships, places, clubs, etc"
                    size="small"
                    className="w-100"
                    onChange={(e: InputEvent) => onChange(e)}
                    variant="outlined"
                    value={search}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </form>
              </Box>
            )}*/}

                        {/**
                         * ******* Mobile Nav
                         */}

                        <Box display="flex" minHeight="1rem" className={classes.mobilenav} alignItems="center">

                            {/*{appUser && Object.keys(appUser).length > 0 && (
                <NavLink to="/tokens" className={classes.typography} activeClassName={classes.selected}>
                  <Box px={4} sx={{ display: 'flex', flexDirection: { xs: 'column' } }} >
                    <HomeLogo />
                    <Typography fontWeight={500} className={`${classes.menu}`} sx={{ mt: { xs: 1 }}} ml={1}>
                      Home
                    </Typography>
                  </Box>
                </NavLink>
              )}*/}

                            <NavLink to="/membership" className={classes.typography} activeClassName={classes.selected}>
                                <Box px={4} sx={{display: 'flex', flexDirection: {xs: 'column'}}}>
                                    <HomeLogo/>
                                    <Typography fontWeight={500} className={`${classes.menu}`} sx={{mt: {xs: 1}}}
                                                ml={1}>
                                        Membership
                                    </Typography>
                                </Box>
                            </NavLink>


                            {/*{isCustomer() && (
                <NavLink to="/marketplace" className={classes.typography} activeClassName={classes.selected}>
                  <Box borderRight="1px solid #E2E8F0" px={4} sx={{ display: 'flex', flexDirection: { xs: 'column' } }} >
                  <Box px={4} sx={{ display: 'flex', flexDirection: { xs: 'column' } }} >
                    <ExploreIcon fontSize="small" style={{ alignSelf: 'center' }} />
                    <Typography fontWeight={500} className={`${classes.menu}`} sx={{ mt: { xs: 1 } }} ml={1}>
                      Marketplace
                    </Typography>

                  </Box>

                </NavLink>
              )}*/}

                            {appUser && Object.keys(appUser).length > 0 && (
                                <>

                                    <Box px={4} sx={{
                                        display: retrieveMemberId() === -1 ? 'none' : 'flex',
                                        flexDirection: {xs: 'column'},
                                        cursor: 'pointer'
                                    }} onClick={createTokenActivation}>
                                        <MembershipLogo/>
                                        <Typography fontWeight={500} className={`${classes.menu}`} sx={{mt: {xs: 1}}}
                                                    ml={1}>
                                            Card
                                        </Typography>
                                    </Box>

                                    <NavLink to="/restaurant-list" className={classes.typography}
                                             activeClassName={classes.selected}>
                                        <Box px={4} sx={{display: 'flex', flexDirection: {xs: 'column'}}}>
                                            <RestaurantIcon/>
                                            <Typography fontWeight={500} className={`${classes.menu}`}
                                                        sx={{mt: {xs: 1}}} ml={1}>
                                                Restaurants
                                            </Typography>
                                        </Box>
                                    </NavLink>

                                    <NavLink to="/announcements" className={classes.typography}
                                             activeClassName={classes.selected}>
                                        <Box px={4} sx={{display: 'flex', flexDirection: {xs: 'column'}}}>
                                            <AnnouncemenetLogo/>
                                            <Typography fontWeight={500} className={`${classes.menu}`}
                                                        sx={{mt: {xs: 1}}} ml={1}>
                                                Announcement
                                            </Typography>
                                        </Box>
                                    </NavLink>

                                </>
                            )}

                            {/*{isCustomer() && appUser && Object.keys(appUser).length > 0 && (
                  <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, ml: { xs: ' auto', sm: ' auto' }, mt: { md: .5 }, textAlign: 'center' }} className="w-25">
                    <Button type="button" color="primary" id="notification-dropdown"
                            style={{ padding: '0px' }}
                            aria-controls="notification-dropdown"
                            aria-haspopup="true"
                            aria-expanded={openNotification ? 'true' : undefined}
                            onClick={handleNotificationClick}
                    >
                      <BellIcon />
                      <ExpandMoreIcon fontSize='small' color='action' sx={{ display: { xs: 'none' } }} />
                    </Button>
                  </Box>
              )}*/}

                            {appUser && Object.keys(appUser).length > 0 && (
                                <Box sx={{display: {xs: 'block', sm: 'block', md: 'none'}}}>

                                    <div className={classes.typography}>

                                        <Box sx={{display: 'flex', flexDirection: {xs: 'column'}}}>

                                            <Button type="button" color="primary" id="setting-dropdown"
                                                    style={{padding: '0px'}}
                                                    aria-controls="basic-menu"
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                            >
                                                <ProfileLogo/>
                                                <ExpandMoreIcon fontSize='small' color='action'
                                                                sx={{display: {xs: 'none'}}}/>
                                            </Button>
                                            <Typography fontWeight={500} className={`${classes.menu}`}
                                                        sx={{mt: {xs: 1}, ml: {xs: 1.5}, textAlign: 'center'}}>
                                                Profile
                                            </Typography>
                                        </Box>
                                    </div>
                                </Box>
                            )}

                        </Box>

                        {/**
                         * ******* Desktop Nav
                         */}
                        {appUser && Object.keys(appUser).length > 0 && (

                            <Box sx={{display: {xs: 'none', sm: 'none', md: 'block'}}}>

                                <div className={classes.sectionDesktop}>
                                    {/*{isCustomer() && (
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, mr: { md: 1.5 }, mt: { md: .5 } }} >
                      <Button type="button" color="primary" id="notification-dropdown"
                        style={{ padding: '10px' }}
                        aria-controls="notification-dropdown"
                        aria-haspopup="true"
                        aria-expanded={openNotification ? 'true' : undefined}
                        onClick={handleNotificationClick}
                      >
                        <BellIcon
                          aria-controls="notification-dropdown"
                          aria-haspopup="true"
                          aria-expanded={openNotification ? 'true' : undefined}
                          onClick={handleNotificationClick}
                        />
                        <ExpandMoreIcon fontSize='small' color='action' sx={{ display: { xs: 'none' } }} />
                      </Button>

                    </Box>
                  )}*/}

                                    <Box display="flex" alignItems="center">
                                        <Button type="button" color="primary" id="setting-dropdown"
                                                aria-controls="basic-menu"
                                                aria-haspopup="true"
                                                aria-expanded={open ? 'true' : undefined}
                                                onClick={handleClick}>
                                            {isCustomer() && (
                                                <Typography fontWeight={600}>
                                                    {`${userProfile?.first_name} ${userProfile?.last_name}`}
                                                </Typography>

                                            )}
                                            {isBusiness() && (
                                                <Typography fontWeight={600}>
                                                    {`${userProfile.business?.name}`}
                                                </Typography>

                                            )}
                                            &nbsp;&nbsp;
                                            <ProfileLogo/>
                                            <ExpandMoreIcon fontSize='small' color='action'/>
                                        </Button>
                                    </Box>
                                </div>
                            </Box>
                        )}

                        {!appUser || (appUser && Object.keys(appUser).length === 0) && (
                            <div className={classes.sectionDesktop}>
                                <Box display="flex" className={classes.grey} alignItems="center" marginLeft="20px">
                                    <Button color="primary" type="button" component={RouterLink}
                                            to={`/auth/login/?returnUrl=${window.location.pathname}`}>
                                        Login
                                    </Button> &nbsp;&nbsp;
                                    <Button variant="contained" color="primary" type="button" component={RouterLink}
                                            to={`/auth/signup/?returnUrl=${window.location.pathname}`}>
                                        Join Now
                                    </Button>
                                </Box>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
                <Menu
                    id="setting-dropdown"
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    style={{top: 35}}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    {/*<Wallet type={WalletTags.menu} />*/}
                    <MenuItem onClick={() => redirect('/settings')}>Settings</MenuItem>
                    <MenuItem onClick={onLogout}>Logout</MenuItem>
                </Menu>

                <Menu
                    id="notification-dropdown"
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorEl={notificationAnchorEl}
                    open={openNotification}
                    onClose={handleNotificationClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    style={{top: 35}}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    {commonSlice.announcementNotifications?.data?.map(val => (
                        <MenuItem key={val.id} onClick={() => readNotification(val.id)}>
                            {val.subject}
                        </MenuItem>
                    ))}
                    <MenuItem sx={{borderTop: '1px solid #000'}}>
                        <a href="/announcements" className='w-100 text-center'
                           style={{textDecoration: 'none', color: '#000', fontSize: '13px'}}>See all announcements</a>
                    </MenuItem>
                </Menu>
            </div>
        </>
    );
}
