import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useHistory } from 'react-router-dom';

import styles from '@auth/styles';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import {resetRegister, saveDetail, signupUserSelector} from '@store/slices/auth.slice';
import {inviteCodeSchema, userNameSchema} from '@auth/auth.schema';
import { UseFormTextField } from '@shared/components/UseFormTextField';
import {isBusiness, isCustomer, showContentByENV, useQuery} from '@shared/helpers/global.helper';
import {authService} from "@services/auth.service";
import {toasterService} from "@services/toaster.service";
import {useEffect} from "react";

const useStyles = makeStyles(styles as any);

export const InviteCode = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const user = useAppSelector(signupUserSelector)
    const history = useHistory();
    const query = useQuery();
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(inviteCodeSchema),
    });

    const onSubmit = async (event: any) => {
        try {
            console.log(event.code)
            const inviteValid = await authService.isInviteValid(event.code);

            if (!inviteValid) {
                toasterService.warn('This invite code is not valid. Please check that it is correct and try again.')
                return;
            }
            dispatch(saveDetail({invite_code: event.code}));
            history.push(`/auth/signup/register${returnUrl}`);
        } catch (error) {

        }
    }

    useEffect(() => {
        dispatch(resetRegister())
    }, [])

    return (
        <div className={classes.boxDimension}>
            <Typography variant="h6" className={classes.title} gutterBottom>
                {isCustomer() && (
                    <> Please enter your invite code to continue</>
                )}
            </Typography>
            <Box mt={4} width="100%">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} >
                    <Grid container justifyContent="center" direction="column" spacing={2}>
                        <Grid item>
                            <UseFormTextField control={control} name="code" placeholder="Invite Code" defaultValue={user.invite_code} />
                        </Grid>
                    </Grid>
                    <Box width={1} py={2} className="flex-justify-center" sx={{ flexDirection: 'column' }}>
                        <Button className="w-100" variant="contained" color="primary" type="submit">
                            Continue
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="w-100" variant="contained" color="primary" href={"mailto:reserve-support@reserveexp.com?subject=Request%20Invite%20Code&body=Name%3A%0D%0AEmail%3A%0D%0ASocial%20Media%20Handles%3A%0D%0AHow%20did%20you%20hear%20about%20Reserve%3A%0D%0A"} target="_blank" rel="noreferrer">
                            Request Invite Code
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="w-100" variant="outlined" color="primary" component={Link} to="/">
                            Return Home
                        </Button>&nbsp;&nbsp;

                    </Box>
                </form>
            </Box>
        </div>
    )
}
