import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    Divider,
    Grid,
    Link, ListItem,
    ListItemAvatar, ListItemIcon, ListItemText,
    Paper,
    Theme,
    Typography,
    Accordion, AccordionSummary, AccordionDetails, Container
} from '@mui/material'

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MovieIcon from '@mui/icons-material/Movie';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import LinkIcon from '@mui/icons-material/Link';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import {makeStyles} from '@mui/styles';
import {Link as RouterLink} from 'react-router-dom';
import {BLOCKCHAIN_NETWORK_ID, CURRENCY} from '@shared/constants/app.constant';

import {IToken} from 'src/interfaces/token';
import {AppSlider} from './Slider';
import {TokenOverviewSkeleton} from '@shared/skeletons/TokenPreviewSkeleton';
import {
    currencyConversion,
    formatPrice,
    getCurrencySymbol,
    getOtherCurrency,
    parseJSON
} from '@shared/helpers/global.helper';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useAppSelector} from '@store/hooks';
import {appSelector} from '@store/slices/app.slice';
import CardContent from "@mui/material/CardContent";
import List from "@mui/material/List";
import LogoPlaceholder from '@assets/images/BusinessLogoPlaceholder.svg'
import DOMPurify from "dompurify";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {appIsMobile} from "@shared/helpers/global.helper";


const useStyles = makeStyles((theme: Theme) => ({
    tokenImage: {
        marginBottom: '24px'
    },
    button: {
        padding: '12px 24px !important',
        borderRadius: '8px !important'
    },
    buttonLabel: {
        fontSize: '14px !important',
        lineHeight: '16px !important',
        fontStyle: 'normal !important',
        letterSpacing: '0.05em !important'
    },
    title2: {
        fontSize: '24px !important',
        lineHeight: '28px !important',
        fontStyle: 'normal',
        marginBotton: '8px'
    },
    title3: {
        fontSize: '20px !important',
        lineHeight: '24px !important',
        fontStyle: 'normal',
        color: '#1E293B !important',
        letterSpacing: '0.0075em',
        marginBottom: '8px'
    },
    body1: {
        fontSize: '16px !important',
        lineHeight: '24px !important',
        fontStyle: 'normal',
        color: '#1E293B !important',
        letterSpacing: ' 0.0168em'
    },
    header: {
        marginBottom: '8px'
    },
    green: {
        color: 'green'
    },
    tokenInfo: {
        textAlign: 'center'
    },
    stakesLogo: {
        marginBottom: '20px'
    },
    overview: {
        backgroundColor: `${theme.palette.primary.dark} !important`,
        color: `${theme.palette.background.paper} !important`,
        padding: '20px',
        marginTop: '20px'
    },
    thumbnail: {
        width: '100%',
        height: '132px',
        borderRadius: '5px'
    },
    infoBox: {
        border: '1px solid #E2E8F0',
        borderRadius: '12px',
        padding: '15px',
        textAlign: 'center',
        backgroundColor: 'white'
    },
    block: {
        display: 'block'
    },
    expired: {
        color: 'red !important',
        padding: '15px'
    }
}));

const LearnMore = ({token}: { token: IToken }) => {
    const classes = useStyles();

    return (
        <Box className={classes.infoBox}>
            <img src={token?.business?.logo?.file || LogoPlaceholder }
                 style={{height: '100px', width: '100px', objectFit: 'cover'}} alt='Owner'/>
            <br/><br/>
            <Box mb={1} textAlign="center">
                <Typography className={'wrapword'} color={'#475569'}>
                    {token?.business?.name}
                </Typography>
            </Box>
            <Box textAlign="left">
                <Typography className={'body2 wrapword'} color={'#475569'}>
                    {(token?.business?.description && token?.business?.description.length > 250) ? token?.business?.description.slice(0, 249) + '...' : token?.business?.description}
                </Typography>
            </Box>
            <br/>
            <Button variant='contained' className={'button w-100'}
                    component={RouterLink} to={`/business-page/${token?.business?.id}`}>
                <Typography fontWeight={700} className={'button-label'}>
                    Learn more
                </Typography>
            </Button>
        </Box>

    )
}

const TokenStats = ({token}: { token: IToken }) => {
    const classes = useStyles();

    let isOwnedToken = token?.parent_id && token?.owner_info?.expiration_readable && token?.profile?.renewal_price;

    return (
        <Box mt={1} className={classes.infoBox} sx={{ background:'none', border: 'none', padding: '0'}}>
            <Box mt={1} textAlign="center">
                <Typography className={'wrapword'} color={'#475569'} sx={{fontWeight: '700', fontSize: '18px !important'}}>
                    {(isOwnedToken ? 'Your Membership' : 'Membership Details')}
                </Typography>
            </Box>
            <List dense={true}>

                {/*{isOwnedToken && (
                <ListItem key={'token_id'}>
                    <ListItemIcon>
                            <CardMembershipIcon />
                    </ListItemIcon>
                    <ListItemText primary="Membership ID" secondary={token?.token_id_bc} />
                </ListItem>
                )}*/}
                {isOwnedToken && (
                    <ListItem key={'expiration'}>
                    {/*<ListItemIcon>
                            <AccessTimeIcon />
                    </ListItemIcon>*/}
                    {/*<ListItemText sx={token?.owner_info?.status === 'EXPIRED' ? {color: 'red !important'} : {}} primary="Token Expiration" secondary={token?.owner_info?.expiration_readable} />*/}
                    <ListItemText secondaryTypographyProps={token?.owner_info?.status === 'EXPIRED' ? {sx: {color: 'red !important'}} : {}} primary="Membership Expiration" secondary={token?.owner_info?.expiration_readable} />
                </ListItem>
                )}
                {/*{isOwnedToken && (
                    <ListItem key={'owner_address'}>
                        <ListItemIcon>
                            <AccountBalanceWalletIcon />
                        </ListItemIcon>
                        <ListItemText primary="Owner Address" secondary={token?.owner_info?.address} />
                    </ListItem>
                )}*/}
                {/*{!isOwnedToken && (
                    <ListItem>
                        <ListItemIcon>
                            <ManageSearchIcon />
                        </ListItemIcon>
                        <ListItemText primary="Token Contract" secondary={token?.owner_info?.expiration_readable} />
                    </ListItem>
                )}*/}
                {!isOwnedToken && (
                    <ListItem key={'membership_duration'}>
                       {/* <ListItemIcon>
                            <AccessTimeIcon />
                        </ListItemIcon>*/}
                        <ListItemText primary="Membership Duration" secondary={+token?.profile?.expiration_duration === -1 ? "Never Expires" : token?.profile?.expiration_duration + " " + token?.profile?.expiration_duration_type} />
                    </ListItem>
                )}
                {/*{!isOwnedToken && (
                    <ListItem key={'memberships_per_addr'}>
                        <ListItemIcon>
                            <Grid3x3Icon />
                        </ListItemIcon>
                        <ListItemText primary="Memberships per Address" secondary={token?.profile?.keys_per} />
                    </ListItem>
                )}*/}

                <ListItem key={'renewal'}>
                    {/*<ListItemIcon>
                            <PriceCheckIcon />
                    </ListItemIcon>*/}
                    <ListItemText primary="Renewal Price" secondary={`${Number.parseInt(token.profile.renewal_price.toString()) === 0 ? "" : getCurrencySymbol(token?.profile?.currency)} ${Number.parseInt(token.profile.renewal_price.toString()) === 0 ? "FREE" : formatPrice(token.profile.renewal_price, token?.profile?.currency)}`} />
                </ListItem>

                {/*<ListItem key={'network'}>
                    <ListItemIcon>
                        <ManageSearchIcon />
                    </ListItemIcon>
                    <ListItemText primary="Blockchain Network" secondary={BLOCKCHAIN_NETWORK_ID[token?.profile?.blockchain_network_id]} />
                </ListItem>*/}

            </List>
        </Box>

    )
}

export const TokenOverview = ({token, isOwned, children}: { token: IToken, isOwned: boolean, children: React.ReactNode }) => {
    const classes = useStyles();
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const appSlice = useAppSelector(appSelector)


    const breadcrumbs = [
        <Link key="1" component={RouterLink} to="/public/tokens" color="inherit" href="/">
            Marketplace
        </Link>,
        <Typography key="3" color="inherit">
            {token?.profile?.title}
        </Typography>
    ];

    function secondsToDhms(seconds: number) {
        seconds = Number(seconds);
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds % (3600 * 24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);

        var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    }

    return (
        <>
            {!token || Object.keys(token).length === 0 ? (
                <TokenOverviewSkeleton/>
            ) : (
                <>

                    <Grid container mt={0} columnSpacing={0} sx={{padding: '0', flexDirection: {md: 'column-reverse', xs: 'column-reverse'}}}>
                        <Grid item md={12} xs={12}>
                            <Box mb={0}>
                                <Card sx={{borderRadius: '0 !important', padding: '0 !important'}}>

                                    <Box mt={0} mb={2} >
                                        {/* CAROUSEL CODE */}
                                        <AppSlider images={token?.profile?.covers?.map(x => x.file)}/>

                                        {/*About Reserve*/}
                                        <Box mt={0} mb={0} sx={{padding: '30px 32px'}}>
                                            <Typography className={'title4'} color={'#1E293B'} mb={2}>
                                                Reserve Membership
                                            </Typography>
                                            {/*<Typography fontWeight={300} className={'body1 wrapword'} color={'#475569'} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(token?.profile?.description || '' )}}>*/}
                                            <Typography fontWeight={300} fontSize={'1.065rem'} className={'body1 wrapword'} color={'#000'} >
                                                {/*{token?.profile?.description}*/}
                                                Welcome to Reserve. Our members are treated to an elevated experience at the best local restaurants in Miami.
                                                <br /><br />Membership is complimentary and by invitation only.&nbsp;
                                                {!isOwned && (
                                                     <a href="auth/signup" rel="noreferrer">Join now.</a>
                                                )}
                                            </Typography>
                                        </Box>

                                        {/* Become a Member */}
                                        {/* Only show if the user doesn't own a membership */}
                                        {/*{!isOwned && (
                                        <Box mt={0} mb={0} sx={{padding: '30px 32px'}}>
                                            <Typography className={'title4'} color={'#1E293B'} mb={2}>
                                                Become a Member
                                            </Typography>
                                            <Typography fontWeight={300} className={'body1 wrapword'} color={'#475569'}>
                                                {(token?.profile?.status === 'ACTIVE' && token?.profile?.contract_type === 'INTERNAL') && (
                                                    <Button variant="contained" size="large" color="primary" sx={{margin: 'auto', borderRadius: '0 !important', fontSize: '18px !important', width: {md: '25%', xs: '100%'} }}
                                                        component={RouterLink} to={`/auth/signup`}
                                                            // component={RouterLink} to={`/tokens/${token?.id}/buy`}
                                                            >
                                                        Join Now
                                                        <ChevronRightIcon visibility={'auto'} />

                                                    </Button>
                                                )}

                                            </Typography>
                                        </Box>
                                        )}*/}

                                        
                                    </Box>
                                    <br />
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}

        </>
    )
}
