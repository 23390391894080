import { networkService } from "./network-service";

interface AvailabilityModel {
    value: string;
    field: string;
}

class AuthService {
    async isAvailable(model: AvailabilityModel) {
        const response: any = await networkService.post('/auth/check-availability', model);
        return response.data.success
    }

    async isInviteValid(code: String) {
        const response: any = await networkService.post('/auth/check-invite-code', { code: code });
        return response.data.success
    }
}

export const authService = new AuthService();