//Components
import {Box, Typography, Theme, Link} from '@mui/material';
// Assets
import {FooterLogo} from '@shared/components/FooterLogo';
import AppStore from '@assets/images/appstore.svg';
import GooglePlay from '@assets/images/googleplay.svg';

//Style
import {makeStyles} from '@mui/styles';
import React from "react";
import {appIsMobile} from "@shared/helpers/global.helper";

const useStyles = makeStyles((theme: Theme) => ({
    grow: {
        flexGrow: 1,
    },
    toolbar: {
        display: 'flex',
        justifyContent: ' space-between'
    },
    menu: {
        marginLeft: '15px !importaont',

    },
    appbar: {
        color: '#041E42',
        backgroundColor: '#fff'
    },
    link: {
        color: 'inherit',
        textDecoration: 'inherit',
        marginLeft: '10px !important',
    },

    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
        color: 'grey'
    },
    typography: {
        minHeight: '4rem',
        alignItems: 'center',
        display: 'flex',
        marginRight: '20px !important',
        textDecoration: 'none',
        color: 'grey'
    },
    selected: {
        borderBottom: '2px solid #009F4D',
        color: theme.palette.primary.dark
    },
    grey: {
        color: 'grey'
    },

    footer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            padding: '32px 24px'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            maxHeight: 'none',
            textAlign: 'center'
        },
        padding: '10px 80px',
        maxHeight: '400px',
        backgroundColor: '#000000'
    },
    footerLogo: {
        marginBottom: '40px !important'
    },

    col1: {
        maxWidth: '350px',
        width: '100%',
        marginRight: '30px !important',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            maxWidth: 'initial',
            marginRight: '0px !important'
        }
    },
    info: {
        fontFamily: 'Sans Serif',
        fontSize: '14px !important',
        lineHeight: '20px !important',
        fontWeight: 400,
        color: '#E2E8F0',
        // maxWidth: '255px',
        marginBottom: '16px !important',
        textAlign: 'left'
    },
    company: {
        fontFamily: 'Sans Serif',
        fontSize: '14px !important',
        lineHeight: '20px !important',
        fontWeight: 400,
        color: '#E2E8F0',
        // maxWidth: '255px',
        textAlign: 'left'
    },
    infoMargin: {
        marginBottom: '16px !important'
    },
    socialIconMargin: {
        marginRight: '25px !important'
    },
    footerLink2: {
        fontFamily: 'Open Sans',
        fontSize: '14px !important',
        lineHeight: '20px !important',
        fontWeight: 400,
        color: '#E2E8F0 !important',
        // maxWidth: '255px',
        marginBottom: '16px !important',
        textAlign: 'left'
    },

    col2: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridColumnGap: '30px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            textAlign: 'center'
        }
    },
    footerHeading: {
        fontSize: '18px !important',
        lineHeight: '24px !important',
        fontWeight: 700,
        color: '#FFFFFF',
        marginBottom: '24px !important'
    },
    footerLink: {
        fontSize: '14px !important',
        lineHeight: '18px !important',
        fontWeight: 500,
        color: '#D4D4D4',
        textDecoration: 'none',
        marginBottom: '16px !important'
    },
    noTextDecoration: {
        textDecoration: 'none'
    },

    appStoreButton: {
        marginBottom: '20px !important'
    },

    appStoreCol: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}));

export default function Footer() {
    const classes = useStyles();

    return <>
        <div className={classes.footer} style={{minHeight: '100px'}}>
            <Box className={classes.col1} mt={3}>
                {/*<Box className={classes.footerLogo}>
                    <FooterLogo />
                </Box>*/}
                <Typography>
                    <Link className={classes.footerLink2}
                          href="https://reserve-dining-exp-legal.s3.amazonaws.com/privacy-policy.html" target="_blank"
                          rel="noopener noreferrer">{'Privacy Policy'}</Link>
                </Typography>
                <Typography mb={3}>
                    <Link className={classes.footerLink2}
                          href="https://reserve-dining-exp-legal.s3.amazonaws.com/reserve-terms-of-use_consumers.html"
                          target="_blank"
                          rel="noopener noreferrer">{'Terms of Use'}</Link>
                </Typography>
                {!appIsMobile() && (
                    <Link href="https://apps.apple.com/us/app/reserve-experience/id6737087385" target="_blank"
                          rel="noopener noreferrer" mb={5}>
                        <img src={AppStore} alt='App Store Button' className={classes.appStoreButton}/>
                    </Link>
                )}


            </Box>
            <Box className={classes.col2}>

                {/* <Box>
                    <Typography variant="h6" className={classes.footerHeading}>
                        Column one
                    </Typography>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            First link
                        </Typography>
                    </Link>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            Second link
                        </Typography>
                    </Link>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            Third link
                        </Typography>
                    </Link>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            Fourth link
                        </Typography>
                    </Link>
                </Box>
                <Box>
                    <Typography variant="h6" className={classes.footerHeading}>
                        Column two
                    </Typography>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            First link
                        </Typography>
                    </Link>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            Second link
                        </Typography>
                    </Link>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            Third link
                        </Typography>
                    </Link>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            Fourth link
                        </Typography>
                    </Link>
                </Box>
                <Box>
                    <Typography variant="h6" className={classes.footerHeading}>
                        Column three
                    </Typography>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            First link
                        </Typography>
                    </Link>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            Second link
                        </Typography>
                    </Link>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            Third link
                        </Typography>
                    </Link>
                    <Link to="/#" className={classes.noTextDecoration}>
                        <Typography className={classes.footerLink}>
                            Fourth link
                        </Typography>
                    </Link>
                </Box> */}
                {/*<Box className={classes.appStoreCol}>
                    <Typography variant="h6" className={classes.footerHeading}>
                        Column four
                    </Typography>
                    <Link to="/#">
                        <img src={AppStore} alt='App Store Button' className={classes.appStoreButton}/>
                    </Link>
                    <Link to="/#">
                        <img src={GooglePlay} alt='Google Play Button' />
                    </Link>
                </Box>*/}
            </Box>
        </div>
    </>
}
