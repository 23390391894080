import {Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Paper, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Link, useHistory} from 'react-router-dom';

import styles from '@auth/styles';
import {useAppDispatch, useAppSelector} from '@store/hooks';
import {
    questionResponseAction,
    questionsDataSelector,
    saveDetail,
    saveQuestions,
    signUpAction,
    signupUserSelector
} from '@store/slices/auth.slice';
import {userNameSchema} from '@auth/auth.schema';
import {UseFormTextField} from '@shared/components/UseFormTextField';
import {isBusiness, isCustomer, showContentByENV, useQuery} from '@shared/helpers/global.helper';
import {useState} from "react";
import {appUserProfileSelector, appUserSelector} from "@store/slices/app.slice";

const useStyles = makeStyles(styles as any);

export const Q5 = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const query = useQuery();
    const questions = useAppSelector(questionsDataSelector);
    const user = useAppSelector(appUserSelector);
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';

    const MAX_CHECKED = 3000;
    const [numChecked, setNumChecked] = useState(0);

    interface checkboxMap {
        [key: number]: boolean;
    }

    const [checkedMap, setCheckedMap] = useState<checkboxMap>({});

    const {control, handleSubmit} = useForm({});

    const handleCheckboxChange = (event: any) => {
        let checked: boolean = event.target.checked;
        let value: number = +event.target.value;

        if (checked) {
            setNumChecked(numChecked + 1);
            setCheckedMap({[value]: true, ...checkedMap});
        } else {
            setNumChecked(numChecked - 1);
            setCheckedMap(current => {
                const {[value]: number, ...rest} = current;
                return rest;
            })
        }

    };

    const disabledCheck = (boxValue: number) => {

        if (checkedMap[boxValue]) {
            return false;
        } else {
            if (numChecked === MAX_CHECKED) {
                return true;
            }
        }
        return false;
    };

    const onSubmit = (event: any) => {
        dispatch(questionResponseAction({ model: { response: {5: checkedMap, ...questions.response} }, user: user, history, path: `/auth/signup/success${returnUrl}` }));
    }
    return (
        <div className={classes.boxDimension}>
            <Typography variant="h6" className={classes.title} gutterBottom>
                {isCustomer() && (
                    <> (5/5) Which of the following Miami restaurants have you dined at and enjoyed? </>
                )}

            </Typography>
            <Box mt={4} width="100%">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="center" direction="column" spacing={2}>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox/>} value={1} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(1)} label="Sereia"/>
                                <FormControlLabel control={<Checkbox/>} value={2} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(2)} label="Aoko"/>
                                <FormControlLabel control={<Checkbox/>} value={3} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(3)} label="Call Me Gaby"/>
                                <FormControlLabel control={<Checkbox/>} value={4} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(4)} label="Sunny's"/>
                                <FormControlLabel control={<Checkbox/>} value={5} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(5)} label="Silverlake Bistro"/>
                                <FormControlLabel control={<Checkbox/>} value={6} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(6)} label="Doma"/>
                                <FormControlLabel control={<Checkbox/>} value={7} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(7)} label="Kyu"/>
                                <FormControlLabel control={<Checkbox/>} value={8} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(8)} label="Matys"/>
                                <FormControlLabel control={<Checkbox/>} value={9} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(9)} label="Mila"/>
                                <FormControlLabel control={<Checkbox/>} value={10} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(10)} label="Michael's Genuine"/>
                                <FormControlLabel control={<Checkbox/>} value={11} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(11)} label="Surf Club"/>
                                <FormControlLabel control={<Checkbox/>} value={12} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(12)} label="Ariete"/>
                                <FormControlLabel control={<Checkbox/>} value={13} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(13)} label="Boia De"/>
                            </FormGroup>
                        </Grid>

                    </Grid>
                    <Box width={1} py={2} className="flex-justify-center"
                         sx={{flexDirection: {xs: 'column', md: 'row-reverse'}}}>
                        <Button className="w-100" variant="contained" color="primary" type="submit">
                            Continue
                        </Button>&nbsp;&nbsp;

                    </Box>
                </form>
            </Box>
        </div>
    )
}
