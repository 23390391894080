import { networkService } from "./network-service";
import {environment} from "@env/environment";

class TokenService {
    async getTokenById(id: string) {
        return await networkService.get(`/tokens/${id}`)
    }
    async createActivation(id: string | number) {
        return await networkService.post(`/tokens/${id}/activations`);
    }
    async findActivationDetail(id: string) {
        return await networkService.get(`/tokens/${id}/activate`);
    }
    async activateToken(id: string) {
        return await networkService.post(`/tokens/${id}/activate`);
    }
    //TODO: Change this to handle transaction Id from metamask>
    /*async buyToken(id: string, paymentId: string) {
        return await networkService.post(`/tokens/${id}/purchase`, { payment_method_id: paymentId });
    }*/
    async buyToken(id: string, tokenId: string, purchaserAddress: string, txHash: string | null ) {
        return await networkService.post(`/tokens/${id}/purchase?crypto=true`, {
            token_id_bc: tokenId,
            wallet_address: purchaserAddress,
            tx_hash: txHash
        });
    }
    async sellToken(id: string, price: string) {
        return await networkService.post(`/tokens/${id}/sell`, { price });
    }

    async checkout(stripeParams: object) {
        return await networkService.post(`/tokens/checkout`, stripeParams ) ;
    }

    async buyTokenCC(id: string, sessionId: string ) {
        return await networkService.post(`/tokens/${id}/purchase?stripe=true`, {
            session_id: sessionId
        });
    }

    async airdropToken(userId: number, userPhone: string ) {
        return await networkService.post(`/tokens/${environment.DEFAULT_RESERVEAT_TOKEN_ID}/airdrop`, {user: {id: userId, phone_number: userPhone}});
    }

}

export const tokenService = new TokenService();