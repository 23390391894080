import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RouteComponentProps} from "react-router-dom";

import {networkService} from "@services/network-service";
import {tokenService} from "@services/token.service";
import {isCustomer} from "@shared/helpers/global.helper";
import {RootState} from "@store/store";
import {SignIn, Auth, Role, Verification} from "@interfaces/auth";
import {MessageType, resetApp, setAccessToken, setLoggedInUser, setMessage} from "@store/slices/app.slice";
import {RegisterModel} from "@interfaces/register";
import {environment} from "@env/environment";
import {resetTokenSlice} from "./token.slice";
import {toasterService} from "@services/toaster.service";

const initialState: Auth = {
    user: {} as RegisterModel,
    verification: {} as Verification,
    questions: {}
};

export const signUpAction = createAsyncThunk(
    'auth/signupFlow',
    async ({
               history,
               path,
               model
           }: { model: RegisterModel, path: string, history: RouteComponentProps["history"] }, {
               rejectWithValue,
               dispatch
           }) => {
        try {
            const {/* paymentSkipped, paymentType,*/ ...rest} = model;

            const response: any = await networkService.post('/auth/signup', {
                ...rest, role_name: environment.APP_TYPE
            });
            // history.push(path);

            // *** Push token on successful signup
            const tokenResponse = await tokenService.airdropToken(response.data.user_id, (rest.phone_number || ''));

            // *** Login after signup
            dispatch(signInAction({
                model: {email: rest.email || '', password: rest.password || ''},
                path: '/auth/signup/preference/1',
                history,
                returnUrl: ''
            }))
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const signInAction = createAsyncThunk(
    'auth/signIn',
    async ({
               model,
               path,
               history,
               returnUrl
           }: { model: SignIn, path: string, history: RouteComponentProps["history"], returnUrl: string }, {
               rejectWithValue,
               dispatch
           }) => {
        try {
            // Note* : `path` should equal the returnUrl that's been passed along, otherwise a default value (as of writing this, `/tokens`
            const loginResponse: any = await networkService.post('/auth/login', {
                ...model,
                role_name: environment.APP_TYPE
            });
            /*if (loginResponse.data.data.status === 'PENDING' && !loginResponse.data.data.is_email_verified) {
                dispatch(setVerificationData({ ...loginResponse.data.data, ...model }));
                history.push(`/auth/verification${returnUrl}`);
                return;
            }*/
            dispatch(setLoggedInUser({}))
            dispatch(resetApp())
            dispatch(resetAuthSlice())
            dispatch(resetTokenSlice())

            dispatch(setLoggedInUser(loginResponse.data.data));
            dispatch(setAccessToken(loginResponse.headers['x-access-token']));

            if (loginResponse.data.data.status === 'PENDING') {
                dispatch(setVerificationData({...loginResponse.data.data, ...model}));
                history.push(`/auth/signup/preference/1`);
                return;
            }

            history.push(path);
            return loginResponse;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const forgotPasswordAction = createAsyncThunk(
    'auth/forgotPassword',
    async ({email, history}: { email: string, history: RouteComponentProps["history"] }, {
        rejectWithValue,
        dispatch
    }) => {
        try {
            const response = await networkService.post(`/auth/forgot-password`, {
                email,
                role_name: environment.APP_TYPE
            });
            history.push(`/auth/reset-password/${email}`)
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const sendOTPAction = createAsyncThunk(
    'auth/sendOtp',
    async (email: string, {rejectWithValue, dispatch}) => {
        try {
            const response = await networkService.post(`/auth/resend-verification-code`, {email});
            toasterService.success('Verification code has been sent to your registered phone number.')
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const questionResponseAction = createAsyncThunk(
    'auth/questionResponse',
    async ({
               history,
               path,
               model,
               user
           }: { model: any, user: any, path: string, history: RouteComponentProps["history"] }, {
               rejectWithValue,
               dispatch
           }) => {
        try {
            console.log(user);
            const response: any = await networkService.post('/question/response', {
                user_id: user.id, response: model.response, role_name: environment.APP_TYPE
            });
            history.push(path);

        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const verifyOTPAction = createAsyncThunk(
    'auth/verifyOTP',
    async ({
               email,
               code,
               password,
               history,
               queryStringReturnUrl
           }: { email: string, code: string, password: string, history: RouteComponentProps["history"], queryStringReturnUrl: string }, {
               rejectWithValue,
               dispatch
           }) => {
        try {
            const response = await networkService.post(`/auth/verify`, {
                "key": "email",
                "value": email,
                "code": code
            });
            toasterService.success('Your account has been verified successfully.')
            dispatch(signInAction({
                model: {email, password: password},
                path: queryStringReturnUrl,
                history,
                returnUrl: `/returnUrl?=${queryStringReturnUrl}`
            }))
            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const resetPasswordAction = createAsyncThunk(
    'auth/resetPassword',
    async ({
               email,
               password,
               history,
               code
           }: { email: string, password: string, history: RouteComponentProps["history"], code: string }, {
               rejectWithValue,
               dispatch
           }) => {
        try {
            const response = await networkService.put(`/auth/forgot-password`, {password, email, code});
            toasterService.success('Password has been updated successfully.')
            history.push('/auth/login')

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetRegister: (state: Auth) => {
            state.user = {} as RegisterModel;
        },
        saveDetail: (state: Auth, action: PayloadAction<RegisterModel>) => {
            state.user = {...state.user, ...action.payload};
        },
        setVerificationData: (state: Auth, action: PayloadAction<Verification>) => {
            state.verification = action.payload;
        },
        resetVerification: (state: Auth) => {
            state.verification = {} as Verification;
        },
        resetAuthSlice(state) {
            Object.assign(state, initialState)
        },
        saveQuestions(state, action) {
            state.questions = {...state.questions, ...action.payload};
        }
    },
    extraReducers: builder => {

    }
})

export const {reducer: authReducer} = authSlice;
export const {
    resetRegister,
    saveDetail,
    saveQuestions,
    resetVerification,
    setVerificationData,
    resetAuthSlice
} = authSlice.actions;

export const signupUserSelector = (rootState: RootState) => rootState.auth.user;
export const verificationDataSelector = (rootState: RootState) => rootState.auth.verification;
export const questionsDataSelector = (rootState: RootState) => rootState.auth.questions;
