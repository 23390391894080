import * as yup from 'yup';

import { ALPHBETS_ONLY_REGEX, EMAIL_REGEX, NUMBERS_ONLY_REGEX, NUMBER_HYPHEN_REGEX, PASSWORD_REGEX, US_ACCOUNT_NO_REGEX } from '@shared/constants/app.constant';

export const registerSchema = yup.object().shape({
    email: yup.string().required('Email is required.').matches(EMAIL_REGEX, "Please enter a valid email"),
    username: yup.string().required('Username is required.')
        .min(4, 'Username must be at least 4 characters')
        .max(20, 'Username must not be greater than 20 characters'),
    password: yup.string()
        .required('Password is required.')
        .matches(PASSWORD_REGEX, 'Password must consist of a minimum of 8 characters, at least one capital letter, one lowercase letter, one number and one special character.'),
    confirmPassword: yup.string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Confirm Password does not match'),
});

export const businessNameSchema = yup.object().shape({
    businessName: yup.string().required('Business Name is required.')
});

export const userNameSchema = yup.object().shape({
    firstName: yup.string().required('First Name is required.')
        .matches(ALPHBETS_ONLY_REGEX, "First Name can only contain letters.")
        .min(2, 'First Name must be at least 2 characters')
        .max(50, 'First Name must not be greater than 50 characters'),
    lastName: yup.string().required('Last Name is required.')
        .matches(ALPHBETS_ONLY_REGEX, "Last Name can only contain letters.")
        .min(2, 'Last Name must be at least 2 characters')
        .max(50, 'Last Name must not be greater than 50 characters'),
});

export const inviteCodeSchema = yup.object().shape({
    code: yup.string().required('Invite Code is required.')
});



export const adressSchema = yup.object().shape({
    address1: yup.string().required('Address 1 is required.'),
    address2: yup.string().defined().nullable(),
    city: yup.string().required('City is required.')
        .min(2, 'City must be at least 2 characters'),
    state: yup.string().required('State is required.'),
    zip: yup.string().required('Zip Code is required.')
        .matches(NUMBERS_ONLY_REGEX, 'Zip Code can contains only numbers.')
        .min(5, 'Zip Code must be 5 digits')
        .max(5, 'Zip Code must be 5 digits'),
});
export const paymentSchema = yup.object().shape({
    name: yup.string().required('Name is required.').matches(ALPHBETS_ONLY_REGEX, "Name can only contain letters."),
    account_no: yup.string().required('Account number is required.').matches(US_ACCOUNT_NO_REGEX, 'Account number must be 7 - 17 digits.'),
    routing_no: yup.string().required('Routing number is required.')
        .min(9, 'Routing number must be 9 digits')
        .max(9, 'Routing number must be 9 digits')
        .matches(NUMBER_HYPHEN_REGEX, 'Routing number is not valid.'),
    account_type: yup.string().required('Account Type is required.'),
});

export const phoneSchema = yup.object().shape({
    phoneNumber: yup.string().required('Phone Number is required.')
});

export const loginSchema = yup.object().shape({
    email: yup.string().required('Email is required.').matches(EMAIL_REGEX, "Please enter a valid email"),
    password: yup.string()
        .required('Password is required.'),
});
export const forgotPasswordSchema = yup.object().shape({
    email: yup.string().required('Email is required.').matches(EMAIL_REGEX, "Please enter a valid email"),
});

export const resetPasswordSchema = yup.object().shape({
    code: yup.string().required('Verification Code is required.'),
    password: yup.string()
        .required('Password is required.')
        .matches(PASSWORD_REGEX, 'Password must consist of a minimum of 8 characters, at least one capital letter, one lowercase letter, one number and one special character.'),
    passwordConfirmation: yup.string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Confirm Password does not match'),
});
