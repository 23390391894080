import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    Paper,
    Typography
} from '@mui/material'
import {makeStyles} from '@mui/styles';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Link, useHistory} from 'react-router-dom';

import styles from '@auth/styles';
import {useAppDispatch, useAppSelector} from '@store/hooks';
import {questionsDataSelector, saveDetail, saveQuestions, signupUserSelector} from '@store/slices/auth.slice';
import {userNameSchema} from '@auth/auth.schema';
import {UseFormTextField} from '@shared/components/UseFormTextField';
import {isBusiness, isCustomer, showContentByENV, useQuery} from '@shared/helpers/global.helper';
import {useState} from "react";

const useStyles = makeStyles(styles as any);

export const Q3 = () => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const query = useQuery();
    const questions = useAppSelector(questionsDataSelector);
    let returnUrl = query.get("returnUrl") ? `/?returnUrl=${query.get("returnUrl")}` : '';

    const MAX_CHECKED = 3000; // Unlimited hack
    const [numChecked, setNumChecked] = useState(0);

    interface checkboxMap {
        [key: number]: boolean;
    }

    const [checkedMap, setCheckedMap] = useState<checkboxMap>({});

    const {control, handleSubmit} = useForm({});

    const handleCheckboxChange = (event: any) => {
        let checked: boolean = event.target.checked;
        let value: number = +event.target.value;

        if (checked) {
            setNumChecked(numChecked + 1);
            setCheckedMap({[value]: true, ...checkedMap});
        } else {
            setNumChecked(numChecked - 1);
            setCheckedMap(current => {
                const {[value]: number, ...rest} = current;
                return rest;
            })
        }

    };

    const disabledCheck = (boxValue: number) => {

        if (checkedMap[boxValue]) {
            return false;
        } else {
            if (numChecked === MAX_CHECKED) {
                return true;
            }
        }
        return false;
    };

    const onSubmit = (event: any) => {
        dispatch(saveQuestions({response: {3: checkedMap, ...questions.response }}));
        history.push(`/auth/signup/preference/4${returnUrl}`)
    }
    return (
        <div className={classes.boxDimension}>
            <Typography variant="h6" className={classes.title} gutterBottom>
                {isCustomer() && (
                    <> (3/5) Do you enjoy any of the following as a starter?</>
                )}

            </Typography>
            <Box mt={4} width="100%">
                <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="center" direction="column" spacing={2}>
                        <Grid item>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox/>} value={1} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(1)} label="Caesar Salad"/>
                                <FormControlLabel control={<Checkbox/>} value={2} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(2)} label="Crudo"/>
                                <FormControlLabel control={<Checkbox/>} value={3} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(3)} label="Burrata"/>
                                <FormControlLabel control={<Checkbox/>} value={4} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(4)} label="Artichoke Dip"/>
                                <FormControlLabel control={<Checkbox/>} value={5} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(5)} label="Oysters"/>
                                <FormControlLabel control={<Checkbox/>} value={6} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(6)} label="Tuna Tartar"/>
                                <FormControlLabel control={<Checkbox/>} value={7} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(7)} label="Octopus"/>
                                <FormControlLabel control={<Checkbox/>} value={8} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(8)} label="Charcuterie"/>
                                <FormControlLabel control={<Checkbox/>} value={9} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(9)} label="Calamari"/>
                                <FormControlLabel control={<Checkbox/>} value={10} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(10)} label="Meatballs"/>
                                <FormControlLabel control={<Checkbox/>} value={11} onChange={handleCheckboxChange}
                                                  disabled={disabledCheck(11)} label="Escargot"/>
                                
                            </FormGroup>
                        </Grid>

                    </Grid>
                    <Box width={1} py={2} className="flex-justify-center"
                         sx={{flexDirection: {xs: 'column', md: 'row-reverse'}}}>
                        <Button className="w-100" variant="contained" color="primary" type="submit">
                            Continue
                        </Button>&nbsp;&nbsp;

                    </Box>
                </form>
            </Box>
        </div>
    )
}
