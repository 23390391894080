import React from 'react'
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import { ForgotPassword } from '@auth/forgot-password/ForgotPassword';

import { Login } from '@auth/login/Login';
import { Verification } from '@auth/verification/Verification';
import { ResetPassword } from '@auth/reset-password/ResetPassword';
import { RegisterHome } from '@auth/register/views/Home';
import AccountBGImage from '@assets/images/stakes-account-bg.png';
import {Q1} from "@auth/register/views/Q1";
export const Auth = () => {
  let { url } = useRouteMatch();

  return (
    <div style={{ height: '100vh', background: '#d8cebc' }}>

      <Switch>
        <Route path={`${url}/login`}>
          <Login />
        </Route>
        <Route path={`${url}/forgot-password`}>
          <ForgotPassword />
        </Route>
        <Route path={`${url}/reset-password/:email`}>
          <ResetPassword />
        </Route>
        <Route path={`${url}/signup`}>
          <RegisterHome />
        </Route>
        <Route path={`${url}/verification`}>
          <Verification />
        </Route>
        <Route path="/" >
          <RegisterHome />
        </Route>
      </Switch>
    </div>
  )
}
